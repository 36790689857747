import React, { useState, useEffect } from "react";
import axios from "axios";

const Hello = () => {
  const [result, setResult] = useState("message here");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const {data} = await axios.get("/.netlify/functions/hello");
      console.log(data);
      setResult(data)
    } catch (error) {
      console.log(error.response)
    }
  };

  return (
    <>
      <section>
        <h1>Hello world</h1>
        <div>{result}</div>
      </section>
    </>
  );
};

export default Hello;
